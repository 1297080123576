import React from 'react';
import './App.scss';
import Header from './Header';
import Category from './Category';
import Option from './Option';
import Footer from './Footer';
import Gallery from './Gallery';
import foto from './Assets/foto.jpg';
import { ReactComponent as YourSvg } from './Assets/illu.svg';
import { NavLink, Route, Switch, useLocation } from 'react-router-dom';

import poster_1 from './Assets/SoMe 1. frisk facaden op (0-00-00-00).jpg';
import poster_2 from './Assets/SoMe 2. Hvis du vil bygge til (0-00-00-00).jpg';
import poster_3 from './Assets/SoMe 3. Forny indretningen (0-00-00-00).jpg';
import poster_4 from './Assets/SoMe 4. Byg om indvendigt (0-00-00-00).jpg';
import poster_5 from './Assets/SoMe 5. Få en opdateret plantegning (0-00-00-00).jpg';
import poster_6 from './Assets/Main_prisoverslag (0-00-00-00).jpg';
import poster_7 from './Assets/Some7.jpg';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

function App() {
  let location = useLocation();

  return (

    <div className="App">
      <Header />

      <div className='section1'>
        <div className='section-content'>
          <div className='section-content-left'>
            <h3>Det kan være svært at se mulighederne i en bolig, man ser på nettet.</h3>
            <div>
              <p>Med {process.env.REACT_APP_PRODUCT_NAME} kan vi illustrere, hvordan boligen vil se ud efter en ombygning, med noget andet maling på væggene, give indretningsforslag eller placere nye møbler i de forskellige rum.</p>
              <p>{process.env.REACT_APP_PRODUCT_NAME} er den enkle vej til at vise, hvordan en bolig kan forbedres. For mulige købere bliver det lettere at se boligens muligheder, og for dem der skal sælge, kan {process.env.REACT_APP_PRODUCT_NAME} give flere interesserede købere.</p>
            </div>
          </div>
          <div className='section-content-right'>
            <img src={foto} alt='foto' />
          </div>
        </div>
      </div>

      <div className='section2'>
        <div className='section-content'>
          <div className='section-content-left'>
            <YourSvg />
          </div>
          <div className='section-content-right'>
              <h2>Via 5 enkle trin viser vi boligens sande potentiale og sørger for den bedste eksponering</h2>
              <p>Resultatet af {process.env.REACT_APP_PRODUCT_NAME} sikres af bygningskonstruktører, som vurderer konstruktionen og sørger for, at ombygningen kan realiseres. Indretningsarkitekter sikrer den bedste præsentation af boligen.</p>

          </div>
        </div>
      </div>

      <div className='section-options'>
        <div  className='section-options-header'>
          <h2>Løsninger som dækker dit behov</h2>
          <p>Nogle boliger mangler kun den sidste finish, mens andre kræver en ombygning for at være ekstra attraktive. {process.env.REACT_APP_PRODUCT_NAME} indeholder derfor flere tilpassede muligheder.</p>
        </div>

        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames="fade"
            timeout={1000}
          >
        <Switch location={location}>
          <Route path="/galleri1">
            <Gallery galleries={[{
              title: '',
              items:[
              {
                title: 'Eksempel 1',
                before: 'https://client.vrstaging.dk/renderings/7f9205a1-4605-4724-a45c-f20c78c581ac/before',
                after: 'https://client.vrstaging.dk/renderings/7f9205a1-4605-4724-a45c-f20c78c581ac/after',
              },
              {
                title: 'Eksempel 2',
                before: 'https://client.vrstaging.dk/renderings/bbd6e3d3-4d01-4eb2-8501-12cbb79f68a0/before',
                after: 'https://client.vrstaging.dk/renderings/bbd6e3d3-4d01-4eb2-8501-12cbb79f68a0/after'
              },
              {
                title: 'Eksempel 3',
                before: 'https://client.vrstaging.dk/renderings/123a33e3-1afa-4038-a9c5-09a76b87f4da/before',
                after: 'https://client.vrstaging.dk/renderings/123a33e3-1afa-4038-a9c5-09a76b87f4da/after'
              }
              ]}]} />
          </Route>
          <Route path="/galleri2">
            <Gallery galleries={[{
              title: '',
              items:[
              {
                title: 'Eksempel 1',
                before: 'https://client.vrstaging.dk/renderings/91ffa45d-1768-4cfa-b6a9-36f3bb619ba5/before',
                after: 'https://client.vrstaging.dk/renderings/91ffa45d-1768-4cfa-b6a9-36f3bb619ba5/after'
              },
              {
                title: 'Eksempel 2',
                before: 'https://client.vrstaging.dk/renderings/ff57767a-94c8-4d98-a83e-7bcdf07d2d1e/before',
                after: 'https://client.vrstaging.dk/renderings/ff57767a-94c8-4d98-a83e-7bcdf07d2d1e/after'
              },
              {
                title: 'Eksempel 3',
                before: 'https://client.vrstaging.dk/renderings/eb85ccf8-0639-4627-8779-52fe48c6d118/before',
                after: 'https://client.vrstaging.dk/renderings/eb85ccf8-0639-4627-8779-52fe48c6d118/after'
              },
              {
                title: 'Eksempel 4',
                before: 'https://client.vrstaging.dk/renderings/e79a8d8a-9f57-4043-ada5-c99a6a66b832/before',
                after: 'https://client.vrstaging.dk/renderings/e79a8d8a-9f57-4043-ada5-c99a6a66b832/after'
              }
              ]}]} />
          </Route>
          <Route path="/galleri3">
            <Gallery galleries={[{
              title: '',
              items:[
              {
                title: 'Eksempel 1',
                before: 'https://client.vrstaging.dk/renderings/55c9edb4-f4fa-4d05-bc08-766c83869dae/before',
                after: 'https://client.vrstaging.dk/renderings/55c9edb4-f4fa-4d05-bc08-766c83869dae/after'
              },
              {
                title: 'Eksempel 2',
                before: 'https://client.vrstaging.dk/renderings/4b24bd1d-7422-4b3f-927e-4d6f50993193/before',
                after: 'https://client.vrstaging.dk/renderings/4b24bd1d-7422-4b3f-927e-4d6f50993193/after'
              },
              {
                title: 'Eksempel 3',
                before: 'https://client.vrstaging.dk/renderings/13b67a9f-ba51-4062-b845-688b16d67092//before',
                after: 'https://client.vrstaging.dk/renderings/13b67a9f-ba51-4062-b845-688b16d67092/after'
              },
              {
                title: 'Eksempel 4',
                before: 'https://client.vrstaging.dk/renderings/497a9111-bc86-418d-9c06-7024703231d7/before',
                after: 'https://client.vrstaging.dk/renderings/497a9111-bc86-418d-9c06-7024703231d7/after'
              }
              ]}]} />
          </Route>
          <Route path="/galleri4">
            <Gallery galleries={[{
              title: 'Stue',
              items:[
                {
                  title: 'Eksempel 1',
                  before: 'https://client.vrstaging.dk/renderings/0928e89f-ff37-40d9-855c-9b312a36f9f7/before',
                  after: 'https://client.vrstaging.dk/renderings/0928e89f-ff37-40d9-855c-9b312a36f9f7/after'
                },
                {
                  title: 'Eksempel 2',
                  before: 'https://client.vrstaging.dk/renderings/6bd7999c-40fb-47cd-9718-a70601a4bb75/before',
                  after: 'https://client.vrstaging.dk/renderings/6bd7999c-40fb-47cd-9718-a70601a4bb75/after'
                },
                {
                  title: 'Eksempel 3',
                  before: 'https://client.vrstaging.dk/renderings/9a3fcd09-c69e-46dd-b72b-efd6bdc5fa8e/before',
                  after: 'https://client.vrstaging.dk/renderings/9a3fcd09-c69e-46dd-b72b-efd6bdc5fa8e/after'
                },
                {
                  title: 'Eksempel 4',
                  before: 'https://client.vrstaging.dk/renderings/6e89c941-9f1e-452a-b2f0-fea9bda09283/before',
                  after: 'https://client.vrstaging.dk/renderings/6e89c941-9f1e-452a-b2f0-fea9bda09283/after'
                }
              ]},
              {
              title: 'Køkken/alrum',
              items:[
                {
                  title: 'Eksempel 1',
                  before: 'https://client.vrstaging.dk/renderings/b6578bcb-2fd2-4c39-b656-d1d1c7e6dc4c/before',
                  after: 'https://client.vrstaging.dk/renderings/b6578bcb-2fd2-4c39-b656-d1d1c7e6dc4c/after'
                },
                {
                  title: 'Eksempel 2',
                  before: 'https://client.vrstaging.dk/renderings/80d57ed8-2b92-42c3-a89b-4289bc646590//before',
                  after: 'https://client.vrstaging.dk/renderings/80d57ed8-2b92-42c3-a89b-4289bc646590//after'
                },
                {
                  title: 'Eksempel 3',
                  before: 'https://client.vrstaging.dk/renderings/04efb8a1-57c4-4e98-b1ed-717c5891ddd7/before',
                  after: 'https://client.vrstaging.dk/renderings/04efb8a1-57c4-4e98-b1ed-717c5891ddd7/after'
                },
                {
                  title: 'Eksempel 4',
                  before: 'https://client.vrstaging.dk/renderings/fe62519f-2bbe-4060-b4fb-b82c0b8c4c0d/before',
                  after: 'https://client.vrstaging.dk/renderings/fe62519f-2bbe-4060-b4fb-b82c0b8c4c0d/after'
                }
              ]},
              {
              title: 'Badeværelse',
              items:[
                {
                  title: 'Eksempel 1',
                  before: 'https://client.vrstaging.dk/renderings/f21c56a5-a90a-48dd-a657-ef1cae55e41a/before',
                  after: 'https://client.vrstaging.dk/renderings/f21c56a5-a90a-48dd-a657-ef1cae55e41a/after'
                },
                {
                  title: 'Eksempel 2',
                  before: 'https://client.vrstaging.dk/renderings/0b9dea13-159a-4494-a6c0-5c90a4f54855/before',
                  after: 'https://client.vrstaging.dk/renderings/0b9dea13-159a-4494-a6c0-5c90a4f54855/after'
                },
                {
                  title: 'Eksempel 3',
                  before: 'https://client.vrstaging.dk/renderings/20213e1d-334c-4546-ac48-6bb650e80c55/before',
                  after: 'https://client.vrstaging.dk/renderings/20213e1d-334c-4546-ac48-6bb650e80c55/after'
                },
                {
                  title: 'Eksempel 4',
                  before: 'https://client.vrstaging.dk/renderings/b915573f-4501-450d-922c-8a50e490fac6/before',
                  after: 'https://client.vrstaging.dk/renderings/b915573f-4501-450d-922c-8a50e490fac6/after'
                }
              ]},
              {
              title: 'Første sal',
              items:[
                {
                  title: 'Eksempel 1',
                  before: 'https://client.vrstaging.dk/renderings/0f1a19d2-4ea5-4466-8a00-1969603ce17d/before',
                  after: 'https://client.vrstaging.dk/renderings/0f1a19d2-4ea5-4466-8a00-1969603ce17d/after'
                },
                {
                  title: 'Eksempel 2',
                  before: 'https://client.vrstaging.dk/renderings/1b9d0946-a68a-45f8-b7f0-0980687475f3/before',
                  after: 'https://client.vrstaging.dk/renderings/1b9d0946-a68a-45f8-b7f0-0980687475f3/after'
                }
              ]}
              ]} />
          </Route>
        </Switch>
          </CSSTransition>
        </TransitionGroup>

        <Category name='Udvendigt' className='first'>
          <Option number='1' name='Frisk facaden op' description='Med denne løsning får boligen et friskt udseende. En gang maling eller et nyrenset tag kan nemlig gøre en stor forskel for, hvordan en bolig tager sig ud.'>
            <NavLink to='/galleri1'>
              <video muted loop playsInline onMouseOver={(e) => e.currentTarget.play()} onMouseOut={(e) => e.currentTarget.pause()} poster={poster_1}>
                <source src={process.env.PUBLIC_URL + '/SoMe 1. frisk facaden op_2.mp4'} type="video/mp4" />
              </video>
            </NavLink>
            <NavLink className='tryout' to='/galleri1'>Prøv selv her</NavLink>
          </Option>

          <Option number='2' name='Hvis du vil bygge til' description={`Måske mangler huset et ekstra værelse eller en moderne terrasse for at blive ekstra attraktiv. ${process.env.REACT_APP_PRODUCT_NAME} kan også vise den slags større ombygninger, der kan ses udefra.`}>
            <NavLink to='/galleri2'>
              <video muted loop playsInline onMouseOver={(e) => e.currentTarget.play()} onMouseOut={(e) => e.currentTarget.pause()} poster={poster_2}>
                <source src={process.env.PUBLIC_URL + '/SoMe 2. Hvis du vil bygge til_2.mp4'} type="video/mp4" />
              </video>
            </NavLink>
            <NavLink className='tryout' to='/galleri2'>Prøv selv her</NavLink>
          </Option>
        </Category>
        <Category name='indvendigt' className='second'>
          <Option number='3' name='Forny indretningen' description='Nye møbler eller en anden farve på væggene kan forandre et rum fuldstændigt. Det er især relevant, hvis man vil vise et mere moderne udtryk.'>
            <NavLink to='/galleri3'>
              <video muted loop playsInline onMouseOver={(e) => e.currentTarget.play()} onMouseOut={(e) => e.currentTarget.pause()} poster={poster_3}>
                <source src={process.env.PUBLIC_URL + '/SoMe 3. Forny indretningen_2.mp4'} type="video/mp4" />
              </video>
            </NavLink>
            <NavLink className='tryout' to='/galleri3'>Prøv selv her</NavLink>
          </Option>
          <Option number='4' name='Byg om indvendigt' description='Ældre huse kan pludselig få en helt moderne planløsning, hvis man slår en væg ned eller tilføjer et vinduesparti. Med denne løsning får man syn for boligens muligheder.'>
            <NavLink to='/galleri4'>
              <video muted loop playsInline onMouseOver={(e) => e.currentTarget.play()} onMouseOut={(e) => e.currentTarget.pause()} poster={poster_4}>
                <source src={process.env.PUBLIC_URL + '/SoMe 4. Byg om indvendigt_2.mp4'} type="video/mp4" />
              </video>
            </NavLink>
            <NavLink className='tryout' to='/galleri4'>Prøv selv her</NavLink>
          </Option>
        </Category>
        <Category name='Tilvalg' className='last'>
          <Option number='5' name='Få en opdateret plantegning' description='Når vi foreslår alternative planløsninger, udarbejder vi også en plantegning, der passer til købernes potentielle behov. Det gør det lettere at overblik over ændringerne og udvider målgruppen.'>
            <video muted loop playsInline onMouseOver={(e) => e.currentTarget.play()} onMouseOut={(e) => e.currentTarget.pause()} poster={poster_5}>
              <source src={process.env.PUBLIC_URL + '/SoMe 5. Få en opdateret plantegning_1.mp4'} type="video/mp4" />
            </video>
          </Option>
          <Option number='6' name='Få et prisoverslag' description='Uden en økonomisk ramme er, det svært at tage stilling til, om en ombygning er realistisk at gennemføre. Derfor kan bygningskonstruktøren beregne et regionalt prisoverslag. Det giver typisk et incitament til en højere prisvurdering og er juridisk godkendt.'>
            <video muted loop playsInline onMouseOver={(e) => e.currentTarget.play()} onMouseOut={(e) => e.currentTarget.pause()} poster={poster_6}>
              <source src={process.env.PUBLIC_URL + '/Main_prisoverslag.mp4'} type="video/mp4" />
            </video>
          </Option>
          <Option number='7' name='Sociale medier' description='Du får en SoMe-video, hvor køberne får et unikt indblik i mulighederne i lige netop dit hjem. Annoncering på de sociale medier er helt centralt for salget og SoMe-videoen giver en høj eksponering.'>
            <video muted loop playsInline onMouseOver={(e) => e.currentTarget.play()} onMouseOut={(e) => e.currentTarget.pause()} poster={poster_7}>
              <source src={process.env.PUBLIC_URL + '/SoMe 7. Sociale medier_1.mp4'} type="video/mp4" />
            </video>
          </Option>
        </Category>
      </div>
      
      <div className='section3'>
        <h2>Interesseret i at vide mere?</h2>
        <a href={`http://${process.env.REACT_APP_READ_MORE_LINK}`}>Bliv kontaktet af din nærmeste home mægler</a>
      </div>
      <Footer />
    </div>
  );
}

export default App;
