import React from 'react';
import './Footer.scss';
import homeLogo from './Assets/footer-logo.svg';

const Footer = () => {
    const logo = process.env.REACT_APP_REALTOR === 'home' ? homeLogo : homeLogo;

    return <footer>
        <img className='logo' alt='logo' src={logo} />
    </footer>;
}

export default Footer;