import React from 'react';
import './Header.scss';
import homeLogo from './Assets/logo-red.svg';

const Header = () => {
    const logo = process.env.REACT_APP_REALTOR === 'home' ? homeLogo:homeLogo;
    const small = !!(process.env.REACT_APP_TITLE_3);

    return <header>
        <img className='logo' alt='logo' src={logo} />

        <div className={'header' + (small ? ' small' : '')}>
            <div className='title'>
                <h1>{process.env.REACT_APP_TITLE_1}</h1>
                <h2 className={process.env.REACT_APP_TITLE_OUTLINE === 'true' ? 'outline' : ''}>{process.env.REACT_APP_TITLE_2}</h2>
                <h3 className={process.env.REACT_APP_TITLE_OUTLINE === 'true' ? 'outline' : ''}>{process.env.REACT_APP_TITLE_3}</h3>
                <h4>{process.env.REACT_APP_TITLE_4}</h4>
            </div>
            <div className='ipad'>
                <video src={process.env.PUBLIC_URL + '/Samlet SoMe_2.mp4'} autoPlay muted loop playsInline />
            </div>
        </div>
    </header>;
}

export default Header;